@font-face {
font-family: 'inter';
src: url(/_next/static/media/adc5e93683f5a2c6-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'inter';
src: url(/_next/static/media/6ec291e61d343ee3-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: italic;
}@font-face {font-family: 'inter Fallback';src: local("Arial");ascent-override: 90.43%;descent-override: 22.52%;line-gap-override: 0.00%;size-adjust: 107.12%
}.__className_8a3005 {font-family: 'inter', 'inter Fallback'
}.__variable_8a3005 {--font-inter: 'inter', 'inter Fallback'
}

@font-face {
font-family: 'interVariable';
src: url(/_next/static/media/cc27cf3ff100ea21-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 100 700;
font-style: normal;
}@font-face {font-family: 'interVariable Fallback';src: local("Arial");ascent-override: 89.79%;descent-override: 22.36%;line-gap-override: 0.00%;size-adjust: 107.89%
}.__className_8739d9 {font-family: 'interVariable', 'interVariable Fallback';font-style: normal
}.__variable_8739d9 {--font-inter-variable: 'interVariable', 'interVariable Fallback'
}

@font-face {
font-family: 'leagueGothic';
src: url(/_next/static/media/53820c6de9eed3ee-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: 'leagueGothic Fallback';src: local("Arial");ascent-override: 163.28%;descent-override: 39.24%;line-gap-override: 0.00%;size-adjust: 59.25%
}.__className_3ba362 {font-family: 'leagueGothic', 'leagueGothic Fallback'
}.__variable_3ba362 {--font-league-gothic: 'leagueGothic', 'leagueGothic Fallback'
}

